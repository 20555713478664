import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../models/types';
import { JWTService } from './JWT.service';
import { Router } from '@angular/router';

@Injectable()
export class SigninService {
url: string;

constructor(
    private http: HttpClient,
    private jwtService: JWTService,
    private router: Router,
    @Inject('BASE_URL') baseUrl: string,
) {
    this.url = baseUrl;
}

async loginUser(username: string, password: string) {
    const data = {
      'username': username,
      'password': password,
    };
    const jwt = await this.jwtService.Encode(data);
    return this.http.post(this.url + 'api/Auth/LoginAdmin',
    { TokenCL: jwt })
    .toPromise()
    .then((result: ServerResponse) => {
      if (result[0].code === 1) {
        return this.jwtService.Decode(result[0].data)
        .then (res => {
            localStorage.setItem('_user', JSON.stringify(res));
            this.router.navigate(['/pages/iot-dashboard']);
        });
      } else if (result[0].code === 0) {
        throw new Error(result[0].message);
      }
    });
  }

  checkUID(uid: string){
    return this.http.post(`${this.url}api/Auth/getAccess`, {
      UID: uid,
    }).toPromise()
    .then(result => {
      if (result === false) {
        this.http.get('manifest.json', { responseType: 'json' }).subscribe( async name => {
          const urlTPC: string = await name['config_topos'];
          window.location.href = urlTPC;
        });
        return false;
      } else {
        return true;
      }
    });
  }

  async getLogo() {
    return await this.http.get(this.url + 'api/Logo/getLogo').toPromise()
      .then((result: ServerResponse) => {
        return result[0].data;
      })
  }

}
