import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../models/types';
import { Store } from '@ngrx/store';
import { Stalls } from '../models/stalls.model';

@Injectable()
export class StallsService {

    url: string;
    constructor(
        private http: HttpClient,
        private store: Store<Stalls[]>,
        @Inject('BASE_URL') baseUrl: string,
    ) {
        this.url = baseUrl;
    }

    getStalls(maCH: string) {
        return this.http.post(`${this.url}api/Stalls/getDsQuayBan`, {MaCH: maCH}).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code === 1) {
                this.store.dispatch({type: 'INIT_STALLS', stalls: result[0].data});
                return result[0].data;
            } else {
                throw new Error('Lỗi! Không thể lấy danh sách quầy bán.');
            }
        });
    }

    getStores() {
        return this.http.get(`${this.url}api/Stalls/getDsCuaHang`).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code === 1) {
                return result[0].data;
            } else {
                throw new Error('Lỗi! Không thể lấy danh sách cửa hàng.');
            }
        });
    }

    KickLog(key: string) {
        return this.http.post(`${this.url}api/Stalls/getLogKich`, {key}).toPromise()
        .then((result: ServerResponse) => {
            this.store.dispatch({type: 'UPDATE_STALL', Key: key});
        });
    }

    getHistorySignIn(maCH: string, date) {
        return this.http.post(`${this.url}api/Stalls/getHistorySignIn`, {MaCH: maCH, Date: date}).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code = 1) {
                return result[0].data;
            }
        });
    }

}
