import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../../models/types';
import { BgColor } from '../../models/background-color.model';

@Injectable()
export class CustomizedColorService {
    url: string;
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseURL: string,
    ) {
        this.url = baseURL;
    }

    getBackgroundColor() {
        return this.http.get(`${this.url}api/CustomizedColor/getdata_color`).toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                return result.data;
            } else {
                throw new Error(result.message);
            }
        });
    }

    putBackgroundColor(value: BgColor) {
        return this.http.post(`${this.url}api/CustomizedColor/updateBackground_Color`, {
            bg_overall: value.bg_overall,
            bg_content: value.bg_content,
            color_content: value.color_content,
        }).toPromise()
        .then(result => {
            if (result === true) {
                return result;
            } else {
                throw new Error('Lỗi update!');
            }
        })
    }

}
