import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../../models/types';

@Injectable()
export class ChartService {

    url: string;
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string,
    ) {
        this.url = baseUrl;
    }

    getCountLoginDevice() {
        return this.http.get(`${this.url}api/Chart/getCount`).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code === 1) {
                return result[0].data;
            } else {
                throw new Error('Lỗi lấy dữ liệu!');
            }
        });
    }
}
