import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CkeditorService {

    url: string;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseURL: string,
    ) {
        this.url = baseURL;
    }

    getDataFile(value: string) {
        return this.http.post(`${this.url}api/CKEditor/readFile`, {
            name: value,
        }).toPromise()
        .then(result => {
            if (result) {
                return result;
            } else {
                throw new Error('Lỗi load file!');
            }
        });
    }

    updateFile(valueSeleted: string, postData: string) {
        return this.http.post(`${this.url}api/CKEditor/updateFile`, {
            name: valueSeleted,
            postData: postData,
        }).toPromise()
        .then(result => {
            if (result === true) {
                return true;
            } else {
                throw new Error('Lỗi update file!');
            }
        });
    }
}
