import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../models/types';
import { AccessDevices } from '../models/access-devices.model';
import { Stalls } from '../models/stalls.model';
import { v4 as uuid} from 'uuid';

@Injectable()
export class AuthenticateService {

    url: string;
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string,
    ) {
        this.url = baseUrl;
    }

    getAccess_Devices() {
        return this.http.get(`${this.url}api/Authenticate/getAccess_Devices`).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code === 1) {
                return result[0].data;
            }
        });
    }

    getAccess_Stalls() {
        return this.http.get(`${this.url}api/Authenticate/getAccess_Stalls`).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code === 1) {
                return result[0].data;
            }
        });
    }

    setAccess_Devices(value: AccessDevices) {
        const id = uuid();
        return this.http.post(`${this.url}api/Authenticate/createAccess_Device`, {
            UID: value.UID,
            Accessable: value.Accessable,
            Name: value.Name,
            ROW_id: id,
        }).toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                return result.message;
            } else {
                throw new Error(result.message);
            }
        });
    }

    putAccess_Device(value: AccessDevices) {
        return this.http.post(`${this.url}api/Authenticate/updateAccess_Device`, {
            UID: value.UID,
            Accessable: value.Accessable,
            Name: value.Name,
        }).toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                return result.message;
            } else {
                throw new Error(result.message);
            }
        });
    }

    deleteAccess_Device(value: AccessDevices) {
        return this.http.post(`${this.url}api/Authenticate/deleteAccess_Device`, {
            UID: value.UID,
            Accessable: value.Accessable,
            Name: value.Name,
        }).toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                return result.message;
            } else {
                throw new Error(result.message);
            }
        });
    }

    async setAccess_Stall(value: Stalls) {
        return await this.http.post(`${this.url}api/Authenticate/createAccess_Stall`, {
            key: value.key,
            maQuay: value.maQuay,
        }).toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                return result.message;
            } else {
                throw new Error(result.message);
            }
        });
    }

    putAccess_Stall(value: Stalls) {
        return this.http.post(`${this.url}api/Authenticate/updateAccess_Stall`, {
            key: value.key,
            maQuay: value.maQuay,
        }).toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                return result;
            } else {
                throw new Error(result.message);
            }
        });
    }

    deleteAccess_Stall(value: Stalls) {
        return this.http.post(`${this.url}api/Authenticate/deleteAccess_Stall`, {
            key: value.key,
            maQuay: value.maQuay,
        }).toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                return result.message;
            } else {
                throw new Error(result.message);
            }
        });
    }

    redirectTPA(uid: string) {
        this.http.get('manifest.json', { responseType: 'json' }).subscribe( async name => {
          const urlTPA: string = await name['config_uid_admin'];
          // const urlTPA_UID = urlTPA.replace(':uid', uid);
          console.log(urlTPA);
          const urlTPA_UID = '/auth/check-uid/' + uid;
          window.location.href = urlTPA_UID;
        });
      }
}
