import { Stalls } from '../models/stalls.model';
import { ConfigBills } from '../models/config-bills.model';

export function stallReducer(state: Array<Stalls> = null, action: any): Array<Stalls> {
  if (action.type === 'INIT_STALLS') {
    return action.stalls;
  }
  if (action.type === 'UPDATE_STALL') {
    return state.map(stall => {
      if (stall.key === action.Key) {
        const stl: Stalls = stall;
        // stl.Status = false;
        return stall = stl;
      }
      return stall;
    });
  }
  return state;
}


export function configBillsReducer(state: Array<ConfigBills>, action: any): Array<ConfigBills> {
  if (action.type === 'INIT_BILLS') {
    return action.configBills;
  }
  if (action.type === 'UPDATE_BILLS') {
    return state.map(elm => {
      if (elm.MaCH === action.data.MaCH) {
        const stl: ConfigBills = elm;
        stl.BillFooter = action.data.BillFooter;
        stl.BillHeader = action.data.BillHeader;
        return elm = stl;
      }
      return elm;
    });
  }
  if (action.type === 'CREATE_BILLS') {
    return [action.configBills, ...state];
  }
  return state;
}
