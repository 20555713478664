import { Injectable, Inject } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class JWTService {
    private key = '';
    url: string;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string,
    ) {
        this.url = baseUrl;
        this.http.get('manifest.json', { responseType: 'json' }).subscribe( async name => {
            const dataAES = await this.deCryptData(name['topos_on_cloud']);
            this.key = dataAES.replace(/"/g, '');
        });
    }

    enCryptData(data: any) {
        const str = JSON.stringify(data);
        const ciphertext = CryptoJS.AES.encrypt(str, 'secret key 123').toString();
        return ciphertext;
    }

    deCryptData(data: any) {
        const bytes = CryptoJS.AES.decrypt(data, 'secret key 123');
        const orginalText = bytes.toString(CryptoJS.enc.Utf8);
        return orginalText;
    }

    /**
     * Decode
     * @param data Object
     */
    async Encode(data: any) {
        const header = btoa(JSON.stringify(
        {
            'alg': 'HS256',
            'typ': 'JWT',
        }));
        const unreplace_payLoad = btoa(JSON.stringify(data));
        const payLoad = unreplace_payLoad.replace(/\=/g, '');

        const text = CryptoJS.HmacSHA256(header + '.' + payLoad, this.key);
        const secret = CryptoJS.enc.Base64.stringify(text);
        const jwt = header + '.' + payLoad + '.' + secret;
        return jwt;
    }
    async Decode(Token: string): Promise<any> {
        const parts = Token.split('.');
        const header = parts[0];
        const payload = parts[1];
        const secret = parts[2];

        const payloadData = JSON.parse(atob(payload));
        const text = CryptoJS.HmacSHA256(header + '.' + payload, this.key);
        let secretNew = CryptoJS.enc.Base64.stringify(text);

        secretNew = secretNew.split('=')[0];
        secretNew = secretNew.replace(/\+/g, '-') ;
        secretNew = secretNew.replace(/\//g, '_');

        if (secret === secretNew) {
            return Promise.resolve(payloadData);
        } else {
            return Promise.reject(new Error('Lỗi đăng nhập!'));
        }
    }
    GetBytes(s: string) {
        const myB: Array<any> = [];
        const buff = new Buffer(s, 'utf8');
        for (let i = 0; i < buff.length; i++) {
            myB[i] = (buff[i]);
        }
        const bF = Buffer.from(myB);
        return bF;
    }

}
