import { Component } from '@angular/core';

@Component({
  selector: 'ngx-form-elements',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class FormsComponent {
}
